<template>
    <div class="card-table-header">
        <ul class="request__ul new-request__ul">
            <li @click.prevent="setActive(sort.action)" v-for="(sort, index) in filterOptions" :key="index" 
                class="request__li" :class="[mode ? 'request__liday' : 'request__linight',{'request__active': isActive(sort.action)}]">
                <a class="d-flex" style="flex-direction: column; justify-content: space-between;">
                    <p style="font-size:12px;">{{ sort.title }}</p>
                    <div element-loading-spinner="el-icon-loading" v-loading="waitingDataLoad">
                        <div v-if="!waitingDataLoad" style="text-align: center;" >
                            <span class="mb-1 d-block"  style="font-size:14px;">{{ sort.count }} - {{$t('message.deals')}} </span>
                            <!-- <span style="font-size:14px;"><i class="el-icon-coin"></i>  {{ sort.total | formatMoney(2) }} </span> -->
                        </div>
                    </div>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters, mapActions} from 'vuex';
import {i18n} from '@/utils/i18n';

export default {
    name: 'filterTable',
    data: () => ({
        active: 'new_deals',
        waitingDataLoad: true,

        filterOptions: [
            {
                title: i18n.t('message.new_deals'),
                count: 0,
                action: 'new_deals',
                dashboard_show: true,
            },
            {
                title: i18n.t('message.active_deals'),
                count: 0,
                action: 'active_deals',
                dashboard_show: true,
            },
            {
                title: i18n.t('message.sender_filial'),
                count: 0,
                action: 'sender_filial',
                dashboard_show: false,
            },
            {
                title: i18n.t('message.packing'),
                count: 0,
                action: 'packing',
                dashboard_show: false,
            },
            {
                title: i18n.t('message.on_flight'),
                count: 0,
                action: 'on_flight',
                dashboard_show: true,
            },
            {
                title: i18n.t('message.unpacking'),
                count: 0,
                action: 'unpacking',
                dashboard_show: false,
            },
            {
                title: i18n.t('message.reciever_filial'),
                count: 0,
                action: 'reciever_filial',
                dashboard_show: false,
            },
            {
                title: i18n.t('message.partner_delivery'),
                count: 0,
                action: 'partner_delivery',
                dashboard_show: false,
            },
            {
                title: i18n.t('message.delivered_to_client'),
                count: 0,
                action: 'delivered_to_client',
                dashboard_show: false,
            },
            {
                title: i18n.t('message.waiting_payment'),
                count: 0,
                action: 'waiting_payment',
                dashboard_show: false,
            },
            {
                title: i18n.t('message.successfull'),
                count: 0,
                action: 'successfull',
                dashboard_show: true,
            },
        ]
    }),
    watch: {
        active: {
            handler: async function(newVal, oldVal) {
                if(newVal) this.$emit('updateActiveFilter', newVal);
            },
            deep: true,
            immediate: true
        },
        filterOptionsData: {
          handler: async function(newVal, oldVal) {
            if(newVal.length > 5){
                this.filterOptions = JSON.parse(JSON.stringify(newVal));
                this.waitingDataLoad = false;
            }
          },
          deep: true,
          immediate: true
      },
    },
    computed: {
        ...mapGetters({
            mode: "MODE",
            filterOptionsData: "deals/filterOptions",
        }),
    },
    methods: {
        ...mapActions({
            updateFilterOptions: "deals/getFilterOptions",
        }),
        isActive (menuItem) {
            return this.active === menuItem
        },
        setActive (menuItem) {
            this.active = menuItem
        },
        
    }
}
</script>

<style>
.mew-style-khan{
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 99%;
}
.new-request__ul li{
    width: 140px;
    padding: 10px 8px;
    margin-right: 0.5% !important;
    margin-bottom: 7px;
}
.new-request__ul li a {
    justify-content: start;
    text-align: center;
 }
 .new-request__ul{
    justify-content: center;
 }
</style>
